import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import '@fs/react-scripts/polyfills'
import * as Sentry from '@sentry/browser'
import { BrightspotProvider } from '@fs/zion-cms'
import { appPath, mergeSentryConfig, sentryDSN, targetEnv } from '@fs/zion-config'
import zionDebug from '@fs/zion-debug'
import { FeatureFlagsProvider } from '@fs/zion-flags'
import { NoticeLoading } from '@fs/zion-icon'
import { i18n, essentialLocales, setAppLocales } from '@fs/zion-locale'
import Root from '@fs/zion-root'
import { Router } from '@fs/zion-router'
import { AppLayout } from '@fs/zion-ui'
import { useUser } from '@fs/zion-user'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import './locales'
import { ZION_CMS_SITES } from './constants'
import NLIHPS from './NLIHPS.json'

const debug = zionDebug('euronaPages:index')

// Enable local feature flag development using the file "dev.flags.js"
// If that file exports anything truthy it will initialize feature flags in localhost mode
// and use the flags defined in that file, instead of flags defined in split.
const mockFlags = process.env.NODE_ENV === 'development' ? import('./dev.flags').then((m) => m.default) : undefined

// initialize Sentry for the app
if (sentryDSN) {
  // pass Sentry overrides to mergeSentryConfig
  Sentry.init(
    mergeSentryConfig({
      dsn: sentryDSN,
      ignoreErrors: [
        /Version change transaction was aborted/,
        /The operation was aborted/,
        /The transaction was aborted/,
        /WKWebView API client did not respond to this postMessage/,
        /The operation failed for reasons unrelated to the database/,
        /Attempting to change access mechanism/,
        /Failed to fetch/,
        /Cannot read properties of undefined \(reading 'pathname'\)/,
        /Cannot redefine property: BetterJsPop/,
        /Can't find variable: SymBrowser_ModifyAnchorTagWithTarget/,
        /Attempt to delete range from database/,
        /Attempt to get a record from database/,
        /Internal error/,
        /Invalid destructuring assignment target/,
        /is not a valid selector/,
        /Database deleted by request of the user/,
        /Unable to open database file on disk/,
        /A request was aborted, for example through a call to ID/,
        /Error creating Records/,
        /Error looking up record in object store by key range/,
        /Loading CSS chunk/,
        /No identifiers allowed directly after numeric literal/,
        /undefined is not an object \(evaluating 'e.pathname'\)/,
        /undefined is not an object \(evaluating 'n.length'\)/,
        /undefined is not an object \(evaluating 'response.reputation'\)/,
        /SyntaxError: Document.querySelectorAll: 'div:has\(> iframe/,
        /SQLError/,
        /AxiosError/,
        /promise rejection captured with value: Object Not Found Matching/,
        /Object\.hasOwn is not a function/,
        /Loading chunk [0-9]+/,
        /{}/,
      ],
    })
  )
}

setAppLocales(essentialLocales)

// This is where you pass data from the server to the client using the SERVER_DATA global.
// Here we pass the mounted app path from the server to the client.
const base = appPath ? new URL(appPath).pathname : ''
// If the base is "/united-kingdom", get the value "united-kingdom" as the basePath for determining which app to render.
const splitBasePath = base.split('/')
const pathname = window?.location?.pathname
const matches = pathname?.match(/\/(?<localeCode>[a-z]{2,3}(-[a-zA-Z0-9-]*)?)\/?\/(eurona\/pages\/)?([^/]+)/)
const site =
  matches?.length > 4 ? matches[4] : splitBasePath[splitBasePath.length - 1] || pathname?.split('/')?.[1] || ''
const beginLocale = matches?.groups?.localeCode || ''

debug(`base: ${base}`)
debug(`site: ${site}`)

const FrontierRoot = () => (
  <React.StrictMode>
    <Suspense fallback={<NoticeLoading />}>
      <BrightspotProvider sites={ZION_CMS_SITES}>
        <FeatureFlagsProvider mockFlags={mockFlags}>
          <Router basename={base}>
            <RenderApp />
          </Router>
        </FeatureFlagsProvider>
      </BrightspotProvider>
    </Suspense>
  </React.StrictMode>
)

const RenderApp = () => {
  const { signedIn, userLoading } = useUser()
  let url = window.location.href
  const olympics = window.location.pathname.includes('/olympics')
  const hasHidehfParam = window.location.search.includes('hidehf')
  const hasHidehfLocalStorage = window?.localStorage?.getItem('hidehf') === 'true'
  const hidehf = hasHidehfParam || hasHidehfLocalStorage
  const noFatFooter = window.location.pathname.includes('obituaries') || window.location.pathname.includes('related-to')

  if (hidehf) {
    window?.localStorage?.setItem('hidehf', 'true')
  }

  if (url?.toLowerCase().includes('/fsyeurope')) {
    url = `${window.location.origin}/${i18n.language}/info/relatives-at-fsy-europe${window.location.search}`
    window.location.href = url
    return null
  }

  const nlihp = NLIHPS[site]
  if (nlihp) {
    // If NLIHP url path is /{site}, redirect to /{langCode}/{site}
    if (targetEnv !== 'local' && window.location.pathname.startsWith(`/${site}`)) {
      window.location.href = `/${i18n.language}${window.location.pathname}${window.location.search}`
      return null
    }

    // If user is not logged in and locale in URL does not match the supported locale for the site,
    // redirect to the react NLIHP with the locale from the URL.
    const unauthenticated = !userLoading && !signedIn
    if (
      unauthenticated &&
      beginLocale &&
      (nlihp.locale instanceof Array ? !nlihp.locale.includes(beginLocale) : nlihp.locale !== beginLocale)
    ) {
      window.location.href = `/${beginLocale}`
      return null
    }
  }

  const path = window?.location?.pathname.replace(/\/$/, '')
  const isAffiliatesRoot = [
    `/${i18n.language}/affiliates`,
    '/affiliates',
    `/${i18n.language}/affiliates/about`,
    '/affiliates/about',
    `/${i18n.language}/eurona/affiliates`,
    '/eurona/affiliates',
    `/${i18n.language}/eurona/affiliates/about`,
    '/eurona/affiliates/about',
  ].includes(path)

  const header = { hidden: olympics && hidehf }
  const footer = { hidden: olympics && hidehf, useFatFooter: !noFatFooter && !isAffiliatesRoot }

  return (
    <Root analytics footer={footer} header={header}>
      <AppLayout fullWidth>
        <App site={site} />
      </AppLayout>
    </Root>
  )
}

ReactDOM.render(<FrontierRoot />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()
